import { icons } from 'lucide-react';
import BrandIcon from './BrandIcon';
import { cn } from '@/utils';
export default function Icon({
  name,
  color,
  size,
  iconProps,
  type = 'lucide',
  strokeWidth = 1.25,
  className,
  ...props
}) {
  const classes = cn({
    'icon': true,
    [size]: size,
    [className]: className
  });
  if (type === 'brand') {
    return <span className={classes} {...props}>
        <BrandIcon icon={name} />
      </span>;
  }
  const LucideIcon = icons[name];
  if (!LucideIcon) {
    console.warn(`Icon "${name}" not found in Lucide icons`);
    return null;
  }
  return <span className={classes} {...props} data-sentry-component="Icon" data-sentry-source-file="index.jsx">
      <LucideIcon color={color} strokeWidth={strokeWidth} {...iconProps} data-sentry-element="LucideIcon" data-sentry-source-file="index.jsx" />
    </span>;
}