'use client'

import { useEffect, useState } from 'react'

export const useScrollDirection = (ref, SCROLL_THRESHOLD = 100) => {
  const [scrollDirection, setScrollDirection] = useState(null)

  useEffect(() => {
    const element = ref.current
    if (!element) return

    let lastScrollY = element.scrollTop

    const updateScrollDirection = () => {
      const scrollY = element.scrollTop
      const direction = scrollY > lastScrollY ? 'down' : 'up'
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > SCROLL_THRESHOLD ||
          scrollY - lastScrollY < -SCROLL_THRESHOLD)
      ) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
    }

    element.addEventListener('scroll', updateScrollDirection)
    return () => {
      element.removeEventListener('scroll', updateScrollDirection)
    }
  }, [ref, scrollDirection])

  return {
    scrollDirection,
    setScrollDirection,
  }
}
