export const FacebookIcon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-sentry-element="svg" data-sentry-component="FacebookIcon" data-sentry-source-file="BrandIcon.jsx">
    <path d="M426.8 64H85.2C73.5 64 64 73.5 64 85.2v341.6c0 11.7 9.5 21.2 21.2 21.2H256V296h-45.9v-56H256v-41.4c0-49.6 34.4-76.6 78.7-76.6 21.2 0 44 1.6 49.3 2.3v51.8h-35.3c-24.1 0-28.7 11.4-28.7 28.2V240h57.4l-7.5 56H320v152h106.8c11.7 0 21.2-9.5 21.2-21.2V85.2c0-11.7-9.5-21.2-21.2-21.2z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="BrandIcon.jsx" />
  </svg>;
export const InstagramIcon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-sentry-element="svg" data-sentry-component="InstagramIcon" data-sentry-source-file="BrandIcon.jsx">
    <path d="M336 96c21.2 0 41.3 8.4 56.5 23.5S416 154.8 416 176v160c0 21.2-8.4 41.3-23.5 56.5S357.2 416 336 416H176c-21.2 0-41.3-8.4-56.5-23.5S96 357.2 96 336V176c0-21.2 8.4-41.3 23.5-56.5S154.8 96 176 96h160m0-32H176c-61.6 0-112 50.4-112 112v160c0 61.6 50.4 112 112 112h160c61.6 0 112-50.4 112-112V176c0-61.6-50.4-112-112-112z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="BrandIcon.jsx" />
    <path d="M360 176c-13.3 0-24-10.7-24-24s10.7-24 24-24c13.2 0 24 10.7 24 24s-10.8 24-24 24zM256 192c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64m0-32c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="BrandIcon.jsx" />
  </svg>;
export const LinkedInIcon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-sentry-element="svg" data-sentry-component="LinkedInIcon" data-sentry-source-file="BrandIcon.jsx">
    <path d="M417.2 64H96.8C79.3 64 64 76.6 64 93.9V415c0 17.4 15.3 32.9 32.8 32.9h320.3c17.6 0 30.8-15.6 30.8-32.9V93.9C448 76.6 434.7 64 417.2 64zM183 384h-55V213h55v171zm-25.6-197h-.4c-17.6 0-29-13.1-29-29.5 0-16.7 11.7-29.5 29.7-29.5s29 12.7 29.4 29.5c0 16.4-11.4 29.5-29.7 29.5zM384 384h-55v-93.5c0-22.4-8-37.7-27.9-37.7-15.2 0-24.2 10.3-28.2 20.3-1.5 3.6-1.9 8.5-1.9 13.5V384h-55V213h55v23.8c8-11.4 20.5-27.8 49.6-27.8 36.1 0 63.4 23.8 63.4 75.1V384z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="BrandIcon.jsx" />
  </svg>;
export const TwitterIcon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-sentry-element="svg" data-sentry-component="TwitterIcon" data-sentry-source-file="BrandIcon.jsx">
    <path d="M492 109.5c-17.4 7.7-36 12.9-55.6 15.3 20-12 35.4-31 42.6-53.6-18.7 11.1-39.4 19.2-61.5 23.5C399.8 75.8 374.6 64 346.8 64c-53.5 0-96.8 43.4-96.8 96.9 0 7.6.8 15 2.5 22.1-80.5-4-151.9-42.6-199.6-101.3-8.3 14.3-13.1 31-13.1 48.7 0 33.6 17.2 63.3 43.2 80.7-16-.4-31-4.8-44-12.1v1.2c0 47 33.4 86.1 77.7 95-8.1 2.2-16.7 3.4-25.5 3.4-6.2 0-12.3-.6-18.2-1.8 12.3 38.5 48.1 66.5 90.5 67.3-33.1 26-74.9 41.5-120.3 41.5-7.8 0-15.5-.5-23.1-1.4C62.8 432 113.7 448 168.3 448 346.6 448 444 300.3 444 172.2c0-4.2-.1-8.4-.3-12.5C462.6 146 479 129 492 109.5z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="BrandIcon.jsx" />
  </svg>;
export const TikTokIcon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-sentry-element="svg" data-sentry-component="TikTokIcon" data-sentry-source-file="BrandIcon.jsx">
    <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="BrandIcon.jsx" />
  </svg>;
export const YouTubeIcon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-component="YouTubeIcon" data-sentry-source-file="BrandIcon.jsx">
    <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="BrandIcon.jsx" />
  </svg>;
export const SpotifyIcon = () => <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="SpotifyIcon" data-sentry-source-file="BrandIcon.jsx">
    <path d="M128 0C57.308 0 0 57.309 0 128c0 70.696 57.309 128 128 128 70.697 0 128-57.304 128-128C256 57.314 198.697.007 127.998.007l.001-.006Zm58.699 184.614c-2.293 3.76-7.215 4.952-10.975 2.644-30.053-18.357-67.885-22.515-112.44-12.335a7.981 7.981 0 0 1-9.552-6.007 7.968 7.968 0 0 1 6-9.553c48.76-11.14 90.583-6.344 124.323 14.276 3.76 2.308 4.952 7.215 2.644 10.975Zm15.667-34.853c-2.89 4.695-9.034 6.178-13.726 3.289-34.406-21.148-86.853-27.273-127.548-14.92-5.278 1.594-10.852-1.38-12.454-6.649-1.59-5.278 1.386-10.842 6.655-12.446 46.485-14.106 104.275-7.273 143.787 17.007 4.692 2.89 6.175 9.034 3.286 13.72v-.001Zm1.345-36.293C162.457 88.964 94.394 86.71 55.007 98.666c-6.325 1.918-13.014-1.653-14.93-7.978-1.917-6.328 1.65-13.012 7.98-14.935C93.27 62.027 168.434 64.68 215.929 92.876c5.702 3.376 7.566 10.724 4.188 16.405-3.362 5.69-10.73 7.565-16.4 4.187h-.006Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="BrandIcon.jsx" />
  </svg>;
export const TelegramIcon = () => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="TelegramIcon" data-sentry-source-file="BrandIcon.jsx">
    <path clipRule="evenodd" d="m24 12c0 6.6274-5.3726 12-12 12-6.62742 0-12-5.3726-12-12 0-6.62742 5.37258-12 12-12 6.6274 0 12 5.37258 12 12zm-11.57-3.14107c-1.1671.48547-3.49985 1.49027-6.99809 3.01437-.56806.2259-.86563.4469-.89272.663-.04579.3652.41154.509 1.0343.7048.08471.0267.17249.0543.26247.0835.6127.1992 1.43688.4322 1.86535.4414.38865.0084.82244-.1518 1.30135-.4807 3.26854-2.2063 4.95574-3.32149 5.06164-3.34553.0748-.01696.1783-.03829.2485.02408.0701.06235.0633.18045.0558.21215-.0453.1931-1.8405 1.8621-2.7695 2.7258-.2896.2692-.495.4602-.537.5038-.0941.0978-.19.1902-.2821.279-.5692.5487-.99607.9602.0236 1.6322.4901.3229.8822.5899 1.2734.8563.4272.291.8533.5812 1.4046.9426.1405.0921.2746.1877.4053.2808.4972.3545.9439.6729 1.4957.6221.3207-.0295.6519-.331.8201-1.2302.3975-2.1252 1.1789-6.7299 1.3595-8.62742.0159-.16625-.004-.37901-.02-.4724-.016-.0934-.0494-.22647-.1708-.32498-.1438-.11666-.3657-.14126-.465-.13952-.4514.00796-1.1438.24874-4.4764 1.63485z" fill="currentColor" fillRule="evenodd" data-sentry-element="path" data-sentry-source-file="BrandIcon.jsx" />
  </svg>;
export const WhatsAppIcon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-component="WhatsAppIcon" data-sentry-source-file="BrandIcon.jsx">
    <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="BrandIcon.jsx" />
  </svg>;
export default function BrandIcon({
  icon,
  ...props
}) {
  switch (icon) {
    case 'Facebook':
      {
        return <FacebookIcon {...props} />;
      }
    case 'Instagram':
      {
        return <InstagramIcon {...props} />;
      }
    case 'LinkedIn':
      {
        return <LinkedInIcon {...props} />;
      }
    case 'TikTok':
      {
        return <TikTokIcon {...props} />;
      }
    case 'Twitter':
      {
        return <TwitterIcon {...props} />;
      }
    case 'Youtube':
      {
        return <YouTubeIcon {...props} />;
      }
    case 'Spotify':
      {
        return <SpotifyIcon {...props} />;
      }
    case 'Telegram':
      {
        return <TelegramIcon {...props} />;
      }
    case 'Whatsapp':
      {
        return <WhatsAppIcon {...props} />;
      }
    default:
      {
        return null;
      }
  }
}