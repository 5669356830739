'use client'

import { isServer } from '@/utils'

const defaultAnalytics = {
  gtag: null,
  gtagEvent: () => { },
  gtagPageView: () => { },
}

export const useAnalytics = () => {
  if (isServer || !window.gtag) {
    return defaultAnalytics
  }

  const sendEvent = (eventName, eventParams = {}) => {
    if (window.gtag) {
      window.gtag('event', eventName, eventParams)
    }
  }

  const pageView = (page) => {
    if (window.gtag) {
      window.gtag('config', process.env.NEXT_PUBLIC_GA_ID, {
        page_path: page,
      })
    }
  }

  return {
    gtag: window.gtag,
    gtagEvent: sendEvent,
    gtagPageView: pageView,
  }
}

export default useAnalytics