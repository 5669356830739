'use client';

import * as Sentry from "@sentry/nextjs";
import { useEffect } from 'react';
import Button from '@/components/ui/Button';
import { LogoIcon } from '@/components/ui/Logo/Logo';
import { useTranslations } from '@/hooks';
export default function ErrorPage({
  error
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  const t = useTranslations();
  return <div className="not-found" data-sentry-component="ErrorPage" data-sentry-source-file="error.jsx">
      <LogoIcon data-sentry-element="LogoIcon" data-sentry-source-file="error.jsx" />

      <h1>{t('empty_state.error.title')}</h1>

      <p>{t('empty_state.error.subtitle')}</p>

      <Button large variant="gradient" onClick={() => window.location.reload()} label={t('empty_state.error.cta')} className="mt-8" data-sentry-element="Button" data-sentry-source-file="error.jsx" />
    </div>;
}