import {
  useTranslations as useTranslationsNext,
  useLocale as useLocaleNext
} from 'next-intl'
import { ROUTES } from '@/data'
import { Link } from '@/i18n/routing'

export const useTranslations = () => {
  const t = useTranslationsNext()
  const translate = (key, { shouldParse = false, ...options } = {}) => {
    return shouldParse
      ? t.rich(key, {
        p: (chunks) => <p>{chunks}</p>,
        b: (chunks) => <b className="font-semibold">{chunks}</b>,
        i: (chunks) => <i className="italic">{chunks}</i>,
        terms: (chunks) => <Link href={ROUTES.TERMS} className="underline" target="_blank" rel="noopener noreferrer">{chunks}</Link>,
        privacy: (chunks) => <Link href={ROUTES.PRIVACY} className="underline" target="_blank" rel="noopener noreferrer">{chunks}</Link>,
        contract_of_service: (chunks) => <Link href={ROUTES.CONTRACT_OF_SERVICE} className="underline" target="_blank" rel="noopener noreferrer">{chunks}</Link>,
        span: (chunks) => <span>{chunks}</span>,
        br: () => <br />,
        ...options,
      })
      : t(key, options)
  }

  translate.raw = t.raw
  return translate
}

export function useLocale() {
  const locale = useLocaleNext()
  return { locale }
}
