'use client'

import { usePathname } from 'next/navigation'

export function useActiveRoute({ exact = true } = {}) {
  const pathname = usePathname()

  const getCurrentPath = () => {
    return pathname.replace(/^\/[a-z]{2}(?=\/|$)/, '')
  }

  const getRoutePath = (path) => {
    return path.replace(/^\/[a-z]{2}(?=\/|$)/, '')
  }

  const isActive = (route) => {
    const currentPath = getCurrentPath()
    if (exact) return currentPath === route
    return currentPath === route || currentPath.startsWith(route + '/')
  }

  return { isActive, getCurrentPath, getRoutePath }
}

export default useActiveRoute
