'use client'

import { useEffect, useRef, useState } from 'react'

const getElementWidth = (element) => {
  const computedStyle = window.getComputedStyle(element)
  const paddingLeft = parseFloat(computedStyle.paddingLeft)
  const paddingRight = parseFloat(computedStyle.paddingRight)
  return element.offsetWidth - paddingLeft - paddingRight
}

export const useSticky = (config = {}) => {
  const {
    offset = 0,
    styles = {
      position: 'fixed',
      top: 'var(--sticky-top, 0px)',
      zIndex: 20,
    },
    stickyClassName = 'is-sticky'
  } = config

  const [isSticky, setIsSticky] = useState(false)
  const [width, setWidth] = useState(null)
  const elementRef = useRef(null)
  const initialTopRef = useRef(null)

  useEffect(() => {
    const element = elementRef.current
    if (!element) return

    initialTopRef.current = element.getBoundingClientRect().top + window.scrollY

    const updateWidth = () => {
      const parentRect = element.parentElement?.getBoundingClientRect()
      if (parentRect) {
        const parentWidth = getElementWidth(element.parentElement)
        setWidth(parentWidth)
      }
    }

    const handleScroll = () => {
      if (!initialTopRef.current) return

      const scrollPosition = window.scrollY
      const shouldBeSticky = scrollPosition > initialTopRef.current + offset
      setIsSticky(shouldBeSticky)

      if (shouldBeSticky) {
        updateWidth()
      }
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', updateWidth)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', updateWidth)
    }
  }, [offset])

  return {
    elementRef,
    isSticky,
    style: isSticky ? { ...styles, width: width ? `${width}px` : '100%' } : {},
    className: isSticky ? stickyClassName : ''
  }
} 